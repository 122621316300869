<template>
  <form class="needs-validation" novalidate @submit.prevent="submit">
    <h3 v-if="id">{{ $t('Customer n°') }}{{ id }}</h3>
    <div class="form-row">
      <div class="form-group col-md-6">
        <FormInput :id="'first-name'"
                   :label="$t('First name')"
                   :required="true"
                   :disabled="savePending"
                   :check-validity="checkValidity"
                   :initial-value="customer.firstName"
                   :change-callback="newValue => customer.firstName = newValue" />
      </div>
      <div class="form-group col-md-6">
        <FormInput :id="'last-name'"
                   :label="$t('Last name')"
                   :required="true"
                   :disabled="savePending"
                   :check-validity="checkValidity"
                   :initial-value="customer.lastName"
                   :change-callback="newValue => customer.lastName = newValue" />
      </div>
    </div>
    <div class="form-group">
      <FormInput :id="'telephone'"
                 :label="$t('Telephone')"
                 :required="true"
                 :disabled="savePending"
                 :check-validity="checkValidity"
                 :initial-value="telephoneToEdit"
                 :change-callback="newValue => telephoneToEdit = newValue">
        <div class="input-group-prepend">
          <div class="input-group-text">+41</div>
        </div>
      </FormInput>
    </div>
    <div class="form-group">
      <label for="customer_since">{{ $t('Customer since') }}</label>
      <input type="date"
             class="form-control"
             id="customer_since"
             :disabled="savePending"
             v-model="customerSinceToEdit" />
    </div>
    <div class="card p-2">
      <div class="form-group">
        <FormInput :id="'street'"
                   :label="$t('Street')"
                   :required="true"
                   :disabled="savePending"
                   :check-validity="checkValidity"
                   :initial-value="customer.street"
                   :change-callback="newValue => customer.street = newValue" />
      </div>
      <div class="form-row">
        <div class="form-group col-md-4">
          <FormInput :id="'zip'"
                     :label="$t('ZIP code')"
                     :type="'number'"
                     :min="1000" :max="9999"
                     :required="true"
                     :disabled="savePending"
                     :check-validity="checkValidity"
                     :initial-value="customer.zip"
                     :change-callback="checkIfCityAlreadyExistsInData" />
        </div>
        <div class="form-group col-md-8">
          <FormInput :id="'city'"
                     :label="$t('City')"
                     :disabled="savePending"
                     :check-validity="savePending"
                     :initial-value="customer.city"
                     :change-callback="newValue => customer.city = newValue" />
        </div>
    </div>
    </div>
    <div class="form-group card p-2 mt-2">
      <label for="birthday">{{ $t('Birthday' )}}</label>
      <br/>
      <label>
        {{ $t('Only birth year') }}
        <input type="checkbox" v-model="showBirthyearSelector"/>
      </label>
      <input type="number"
             class="form-control"
             id="birthyear"
             :disabled="savePending"
             v-model="customer.birthyear"
             v-if="showBirthyearSelector">
      <input type="date"
             class="form-control"
             id="birthday"
             :disabled="savePending"
             v-model="birthdayToEdit"
             v-else>
    </div>
    <div class="form-row">
      <div class="col-6">
        <button class="btn btn-secondary btn-block"
                @click="cancel"
                :disabled="savePending">
          {{ $t('Cancel') }}
        </button>
      </div>
      <div class="col-6">
        <button type="submit"
                class="btn btn-primary btn-block"
                :disabled="savePending">
          {{ $t('Validate') }}
        </button>
      </div>
    </div>
  </form>
</template>

<script>
import { mapState, mapActions } from "vuex";
import {Customer, loadCities } from "@/api/customer.api";
import FormInput from "@/components/FormInput";
import moment from "moment";

const MOMENT_FORMAT_FOR_DATES = 'yyyy-MM-DD';

export default {
  components: { FormInput },
  data() {
    return {
      id: -1,
      showBirthyearSelector: false,
      birthdayToEdit: null,
      customerSinceToEdit: null,
      telephoneToEdit: null,
      customer: new Customer(),
      citiesByZipCode: [],
      savePending: false,
      checkValidity: false,
    }
  },
  computed: {
    ...mapState({
      customerFromState: function (state) {
        // loading the customer into the view if we landed here as the first view (and the state was not yet loaded with customers)
        if (this.id === -1) {
          return new Customer();
        } else {
          return state.customers.customers.find(customer => customer.id === this.id);
        }
      }
    })
  },
  methods: {
    ...mapActions(['saveCustomer']),
    somethingHasChanged: function (customer1, customer2) {
      return (customer1 && customer2)
          && (customer1.lastName !== customer2.lastName
              || customer1.firstName !== customer2.firstName
              || customer1.street !== customer2.street
              || customer1.zip !== customer2.zip
              || customer1.city !== customer2.city
              || customer1.birthday !== customer2.birthday
              || customer1.birthyear !== customer2.birthyear
              || customer1.customerSince !== customer2.customerSince
          )
    },
    submit: function () {
      this.customer.birthday = moment(this.birthdayToEdit, MOMENT_FORMAT_FOR_DATES);
      this.customer.customerSince = moment(this.customerSinceToEdit, MOMENT_FORMAT_FOR_DATES);
      this.customer.telephone = `+41${this.telephoneToEdit}`;
      this.checkValidity = true;
      if (this.customer.isValid()) {
        if (!this.$route.params.id || this.$route.params.id === 'new' || this.somethingHasChanged(this.customer, this.customerFromState)) {
          this.savePending = true;
          this.saveCustomer(this.customer).then(() => {
            this.savePending = false;
            this.$router.back();
          }).catch(error => {
            console.log('error while saving customer', error);
            this.savePending = false;
          })
        } else {
          this.cancel();
        }
      }
    },
    cancel: function () {
      this.$router.push('/customers');
    },
    checkIfCityAlreadyExistsInData: function () {
      const cityFound = this.citiesByZipCode.find(city => city.zip === Number(this.customer.zip));
      if (cityFound) {
        this.customer.city = cityFound.city;
      }
    },
  },
  mounted() {
    const routeID = this.$route.params.id;
    if (routeID !== 'new') {
      this.id = Number(routeID);
      this.customer = this.customerFromState ? this.customerFromState.clone() : new Customer();
      if (this.customerFromState) {
        this.birthdayToEdit = this.customerFromState.birthday.format(MOMENT_FORMAT_FOR_DATES);
        this.customerSinceToEdit = this.customerFromState.customerSince.format(MOMENT_FORMAT_FOR_DATES);
        this.telephoneToEdit = this.customerFromState.telephone.replace(/\+41/g, '0')
      }
    } else {
      this.customerSinceToEdit = moment().format(MOMENT_FORMAT_FOR_DATES);
    }
    loadCities().then(citiesByZip => {
      this.citiesByZipCode = citiesByZip;
    })
  },
  watch: {
    customerFromState: function (newCustomerFromState) {
      // making a copy of the customer in the state (so that the state stay unchanged as long as "validate" button is not clicked)
      if (newCustomerFromState) {
        this.customer = newCustomerFromState.clone();
      } else {
        this.customer = new Customer();
      }
      this.telephoneToEdit = this.customer.telephone ? this.customer.telephone.replace(/\+41/g, '0') : '';
      this.showBirthyearSelector = this.customer.birthyear && !this.customer.birthday
      if (this.customer.birthday) {
        this.birthdayToEdit = this.customer.birthday.format(MOMENT_FORMAT_FOR_DATES);
      }
      if (this.customer.customerSince) {
        this.customerSinceToEdit = this.customer.customerSince.format(MOMENT_FORMAT_FOR_DATES);
      }
    }
  }
}
</script>
