<template>
  <nav class="navbar navbar-expand-lg fixed-top navbar-dark bg-primary">
    <router-link to="/">
      <font-awesome-icon icon="home" />
    </router-link>
    <router-link to="/">
      <img class="img-fluid" src="../assets/logo.png" alt="logo" />
    </router-link>
    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse" id="navbarNav">
      <ul class="navbar-nav">
        <NavbarButton :title="$t('Home')" route="/" />
        <NavbarButton :title="$t('Customers')" route="/customers" />
      </ul>
    </div>
  </nav>
</template>

<style lang="scss">
@import "~bootstrap/scss/bootstrap";
.navbar-toggler:focus {
  outline-color: $secondary;
}
</style>

<script>
import NavbarButton from "@/components/NavbarButton";
export default {
  components: {NavbarButton}
};
</script>
