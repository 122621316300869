import { createStore } from 'vuex'

import auth from "./modules/auth.store"
import customers from "./modules/customers.store";

import loadCustomersOnLoginPlugin from "./plugins/load-customers-on-login";
import saveTokenOnLoginPlugin from "./plugins/save-token-on-login";
import verifyTokenOnAppLoad from "@/store/plugins/verify-token-on-app-load";

export default createStore({
  state: {
  },
  modules: {
    auth,
    customers
  },
  plugins: [
    loadCustomersOnLoginPlugin,
    saveTokenOnLoginPlugin,
    verifyTokenOnAppLoad
  ]
})
