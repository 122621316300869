import {retrieveAuthToken, verifyToken} from "@/api/login.api";
import router from "@/router";

const verifyTokenOnAppLoad = (store) => {
    const token = retrieveAuthToken();
    if (token) {
        store.dispatch('setToken', token);
        verifyToken(token).then(isTokenValid => {
            store.commit('setIsLoggedIn', isTokenValid)
            if (isTokenValid) {
                router.push('/');
            }
        })
    } else {
        store.commit('isLoggedIn', false);
    }
};

export default verifyTokenOnAppLoad;
