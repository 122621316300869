import store from "../store";
import {verifyToken} from "@/api/login.api";

let firstRoute = true;

const authenticateGuard = router => {
    router.beforeEach((to, from, next) => {
        if (store.state.auth.token) {
            // checking token if first time
            if (firstRoute) {
                firstRoute = false;
                verifyToken(store.state.auth.token).then(isValid => {
                    if (isValid) {
                        next();
                    } else {
                        next({
                            name: 'Login'
                        })
                    }
                })
            } else {
                next();
            }
        } else {
            if (to.name === 'Login') {
                next();
            } else {
                next({
                    name: 'Login'
                })
            }
        }
    })
}

export default authenticateGuard;
