import {createRouter, createWebHashHistory} from "vue-router";
import LoginView from "../views/LoginView";
import HomeView from "@/views/HomeView";
import authenticateGuard from "@/router/authenticate-guard";
import CustomersView from "@/views/CustomersView";
import EditCustomerView from "@/views/EditCustomerView";

const router = createRouter({
    history: createWebHashHistory(),
    routes: [
        {
            path: '/login',
            name: 'Login',
            component: LoginView
        }, {
            path: '/',
            name: 'Home',
            component: HomeView
        }, {
            path: '/customers',
            name: 'Customers',
            component: CustomersView
        }, {
            path: '/customer/:id',
            alias: '/customer/new',
            name: 'Customer',
            component: EditCustomerView
        }
    ],
    scrollBehavior(to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition
        } else {
            return { top: 0 }
        }
    },
})

authenticateGuard(router);

export default router;
