<template>
  <Navbar v-if="isLoggedIn" />
  <div class="container-fluid mx-1 my-5">
    <router-view />
  </div>
</template>

<script>
import { mapState } from 'vuex';
import Navbar from '@/components/Navbar';

export default {
  name: 'App',
  computed: {
    ...mapState({
      isLoggedIn: state => state.auth.isLoggedIn,
    }),
  },
  components: {
    Navbar
  }
}
</script>

<style lang="scss">

@import "~bootswatch/dist/slate/variables";
@import "~bootstrap/scss/bootstrap";
@import "~bootswatch/dist/slate/bootswatch";

#app {
  overflow-x: hidden;
}

</style>
