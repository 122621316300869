<template>
  <img class="img-fluid my-2 mx-auto d-block" src="../assets/full_logo.png" alt="logo" />
  <div class="card mx-auto" style="width: 18rem;">
    <div class="card-body">
      <div class="form-group">
        <label for="username">{{ $t('Username') }}</label>
        <input id="username" class="form-control" type="text" v-model="username" autofocus>
      </div>
      <div class="form-group">
        <label for="password">{{ $t('Password') }}</label>
        <input id="password" class="form-control" type="password" v-model="password" @keydown="onKeyDown">
      </div>
      <button class="btn btn-primary" @click="performLogin">{{ $t('Login') }}</button>
      <div v-if="failed" class="alert alert-danger mt-3" role="alert">
        {{ $t('Wrong username/password') }}
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import login from "@/api/login.api";

export default {
  name: 'login',
  data() {
    return {
      username: '',
      password: '',
      failed: false
    }
  },
  methods: {
    ...mapActions(['setToken', 'setIsLoggedIn']),
    performLogin() {
      this.failed = false;
      login(this.username, this.password).then((token, err) => {
        if (err) {
          this.failed = true;
        } else {
          this.setToken(token);
          this.setIsLoggedIn(true);
          this.$router.push('/');
        }
      }).catch((err) => {
        console.log('Error while login in', err);
        this.failed = true;
      })
    },
    onKeyDown(e) {
      if (e.key === 'Enter') {
        this.performLogin();
      }
    }
  }
}
</script>

<style>
</style>
