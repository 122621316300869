import loadCustomers, {loadCities} from "@/api/customer.api";

const loadCustomersOnLoginPlugin = (store) => {
    store.subscribe((mutation, state) => {
        if (mutation.type === 'setIsLoggedIn' && state.auth.isLoggedIn) {
            loadCustomers().then(customers => {
                store.dispatch('setCustomers', customers);
            })
            loadCities()
        }
    })
};

export default loadCustomersOnLoginPlugin;
