export default {
    state: {
        token: null,
        isLoggedIn: false,
    },
    mutations: {
        setToken: (state, token) => state.token = token,
        setIsLoggedIn: (state, isLoggedIn) => state.isLoggedIn = isLoggedIn,
    },
    actions: {
        setToken: ({commit}, token) => commit('setToken', token),
        setIsLoggedIn: ({commit}, isLoggedIn) => commit('setIsLoggedIn', isLoggedIn),
    }
}
