<template>
  <label :for="id" :class="{ 'text-danger': required && !valid}">{{ `${label}${required ? '*' : ''}` }}</label>
  <div class="input-group">
    <div class="input-group-prepend">
      <slot></slot>
    </div>
    <input class="form-control form-input"
           autocapitalize="words"
           :type="type"
           :min="min"
           :max="max"
           :class="{ 'border-danger': required && !valid }"
           :id="id"
           :disabled="disabled"
           :placeholder="label"
           @focusout="checkIfValid"
           @change="changeCallback(value)"
           v-model="value" />
  </div>
</template>
<style lang="scss">
  @import "node_modules/bootstrap/scss/bootstrap";
  .form-input.border-danger::placeholder {
    color: $red;
  }
</style>
<script>
export default {
  data() {
    return {
      value: this.initialValue,
      valid: true,
    }
  },
  props: {
    id: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
    required: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    initialValue: {
      required: true,
      validator: (val) => val === null || typeof val === 'string' || typeof val === 'number',
    },
    changeCallback: {
      type: Function,
      required: true,
    },
    checkValidity: {
      type: Boolean,
      default: false
    },
    type: {
      type: String,
      default: 'text',
    },
    min: {
      type: Number,
      default: Number.MIN_SAFE_INTEGER,
    },
    max: {
      type: Number,
      default: Number.MAX_SAFE_INTEGER,
    }
  },
  methods: {
    checkIfValid: function () {
      if (this.required && (!this.value || this.value === '')) {
        this.valid = false;
      }
    }
  },
  watch: {
    initialValue: function (newValue) {
      this.value = newValue;
    },
    checkValidity: function (newFlag) {
      if (newFlag) {
        console.log('checking validity')
        this.checkIfValid();
      }
    }
  },
}
</script>
