import axios from 'axios';
import moment from "moment";
import {BACKEND_URL} from "@/config";

function textWithoutDiacritics(text) {
    return text.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
}

export class Customer {
    /**
     * @param {Number} id
     * @param {String} firstName
     * @param {String} lastName
     * @param {String} telephone
     * @param {String} street
     * @param {Number} zip
     * @param {String} city
     * @param {moment.Moment} birthday
     * @param {Number} birthyear
     * @param {moment.Moment} customerSince
     * @param {Array<Address>} addresses
     */
    constructor(id = -1,
                firstName= '',
                lastName= '',
                telephone = '',
                street = '',
                zip = null,
                city = '',
                birthday= null,
                birthyear= null,
                customerSince= null) {
        this.id = id;
        this.firstName = firstName;
        this.lastName = lastName;
        this.telephone = telephone;
        this.street = street;
        this.zip = zip;
        this.city = city;
        this.birthday = birthday;
        if (this.birthday instanceof moment && !this.birthyear) {
            this.birthyear = this.birthday.get('years');
        } else {
            this.birthyear = birthyear;
        }
        this.customerSince = customerSince;
    }

    fullname() {
        return `${this.firstName} ${this.lastName}`;
    }

    age() {
        if (this.birthday) {
            return moment().diff(this.birthday, 'years');
        } else if (this.birthyear) {
            return `~${moment().year() - this.birthyear}`;
        }
        return null;
    }

    /**
     * @param {String} searchString
     * @returns {boolean}
     */
    match(searchString) {
        if (!searchString || typeof searchString !== 'string') {
            return false;
        }
        const searchStringLowerCase = textWithoutDiacritics(searchString.toLowerCase());
        return textWithoutDiacritics(this.firstName.toLowerCase()).indexOf(searchStringLowerCase) !== -1
                || textWithoutDiacritics(this.lastName.toLowerCase()).indexOf(searchStringLowerCase) !== -1
                || textWithoutDiacritics(this.city.toLowerCase()).indexOf(searchStringLowerCase) !== -1
                || textWithoutDiacritics(this.street.toLowerCase()).indexOf(searchStringLowerCase) !== -1;
    }

    isValid() {
        return this.firstName && this.firstName !== '' && this.lastName && this.lastName !== '' && this.telephone && this.telephone !== ''
    }

    clone() {
        return new Customer(this.id, this.firstName, this.lastName, this.telephone, this.street, this.zip, this.city, this.birthday, this.birthyear, this.customerSince);
    }
}

const parseCustomerFromRequest = request => {
    return new Customer(request.id,
        request.first_name,
        request.last_name,
        request.telephone,
        request.street,
        request.zip,
        request.city,
        request.birthday ? moment(request.birthday, 'YYYY-MM-DD') : null,
        request.birthyear,
        request.customer_since ? moment(request.customer_since, 'YYYY-MM-DD') : null)
}

export const saveCustomer = (customer) => {
    return new Promise((resolve, reject) => {
        if (customer instanceof Customer) {
            const payload = {
                id: customer.id === -1 ? null : customer.id,
                first_name: customer.firstName,
                last_name: customer.lastName,
                telephone: customer.telephone,
                street: customer.street,
                zip: customer.zip,
                city: customer.city,
                customer_since: customer.customerSince ? customer.customerSince.format('yyyy-MM-DD') : null,
                birthday: customer.birthday ? customer.birthday.format('yyyy-MM-DD') : null,
                birthyear: customer.birthyear,
            }
            if (customer.id && customer.id !== -1) {
                axios.post(`${BACKEND_URL}/customer/${customer.id}`, payload)
                    .then(savedCustomer => {
                        resolve(parseCustomerFromRequest(savedCustomer.data));
                    })
                    .catch(error => {
                        console.error('Error while saving customer', customer, error);
                        reject();
                    })
            } else {
                axios.put(`${BACKEND_URL}/customer`, payload)
                    .then(savedCustomer => {
                        resolve(parseCustomerFromRequest(savedCustomer.data));
                    })
                    .catch(error => {
                        console.error('Error while saving customer', customer, error);
                        reject();
                    })
            }
        } else {
            reject('bad instance')
        }
    })
};

export const loadCities = () => {
    return new Promise((resolve, reject) => {
        axios.get(`${BACKEND_URL}/cities`).then(response => {
            const cities = [];
            Object.keys(response.data).forEach(zipCode => {
                cities.push({
                    zip: Number(zipCode),
                    city: response.data[zipCode]
                })
            })
            resolve(cities);
        }).catch(error => {
            console.error('Error while loading cities list', error);
            reject(error);
        })
    })
}

const loadCustomers = () => {
    return new Promise((resolve, reject) => {
        axios.get(`${BACKEND_URL}/customers`).then(response => {
            if (response.status === 200 && Array.isArray(response.data)) {
                const customers = [];
                response.data.forEach(customer => {
                    customers.push(parseCustomerFromRequest(customer));
                })
                resolve(customers);
            } else {
                reject();
            }
        }).catch(error => {
            reject(error);
        })
    })
}
export default loadCustomers;
