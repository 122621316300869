import {saveCustomer} from "@/api/customer.api";

export default {
    state: {
        customers: []
    },
    getters: {},
    actions: {
        setCustomers: ({commit}, customers) => {
            if (Array.isArray(customers)) {
                commit('setCustomers', customers);
            }
        },
        saveCustomer: ({commit}, customer) => {
            saveCustomer(customer).then(savedCustomer => {
                commit('addOrReplaceCustomer', savedCustomer);
            })
        }
    },
    mutations: {
        setCustomers: (state, customers) => state.customers = customers,
        addOrReplaceCustomer: (state, customer) => {
           const customerInStore = state.customers.find(customer1 => customer1.id === customer.id);
           if (customerInStore) {
               const alteredCustomers = [...state.customers];
               alteredCustomers.splice(alteredCustomers.indexOf(customerInStore), 1, customer);
               state.customers = alteredCustomers;
           } else {
               state.customers = [...state.customers, customer]
           }
        }
    }
}
