import axios from "axios";
import {BACKEND_URL} from "@/config";

const AUTH_TOKEN_KEY = 'intheair.authToken'
const HTTP_HEADER_AUTH_TOKEN_ENTRY = 'X-Auth-Token';

export const verifyToken = (token) => {
    return new Promise(resolve => {
        axios.post(`${BACKEND_URL}/checkToken`, {
            token
        }).then(response => {
            if (response.status === 200) {
                resolve(true);
            } else {
                resolve(false);
            }
        }).catch(error => {
            console.error('Error while checking token', error)
            resolve(false);
        })
    });
};

export function retrieveAuthToken() {
    const token = localStorage.getItem(AUTH_TOKEN_KEY);
    if (token) {
        console.log('setting axios header ', HTTP_HEADER_AUTH_TOKEN_ENTRY, 'to', token);
        axios.defaults.headers.common[HTTP_HEADER_AUTH_TOKEN_ENTRY] = `${token}`;
    }
    return token;
}
export function storeAuthToken(token) {
    axios.defaults.headers.common[HTTP_HEADER_AUTH_TOKEN_ENTRY] = `${token}`;
    localStorage.setItem(AUTH_TOKEN_KEY, token)
}

const login = (username, password) => {
    return new Promise((resolve, reject) => {
        axios.post(`${BACKEND_URL}/login`, {
            username,
            password
        }).then(response => {
            if (response.status === 200) {
                resolve(response.data);
            } else {
                reject('Bad login');
            }
        }).catch(error => {
            console.debug('error while login', error);
            reject('Bad login')
        })
    })
}

export default login;
