<template>
  <li class="nav-item" :class="{ 'active': isActive }">
    <router-link :to="route">
      <a class="nav-link" :class="{ 'disabled': disabled }" href="#">{{ title }} <span class="sr-only" v-if="isActive">(current)</span></a>
    </router-link>
  </li>
</template>

<script>
export default {
  props: {
    title: {
      type: String
    },
    route: {
      type: String,
      default: '/'
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    currentRoutePath: function () {
      return this.$route.path;
    },
    isActive: function () {
      return this.route === this.currentRoutePath;
    }
  }
};
</script>