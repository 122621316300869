<template>
  <div class="card text-center p-3">
    <font-awesome-icon class="m-auto" :icon="icon" size="5x" />
    <slot></slot>
  </div>
</template>

<script>
export default {
  props: {
    icon: {
      type: String,
      default: 'home'
    }
  }
}
</script>