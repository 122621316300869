<template>
  <div id="home-view">
    <router-link to="/customers">
      <HomeViewButton icon="users">
        {{ $t('Customers' )}}
      </HomeViewButton>
    </router-link>
  </div>
</template>

<style>
#home-view {
  display: grid;
  grid-template-columns: 1fr 1fr;
}
</style>

<script>
import HomeViewButton from "@/components/HomeViewButton";
export default {
  components: {HomeViewButton}
};
</script>