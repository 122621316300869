import 'bootstrap';

import { createApp } from 'vue'
import router from './router';
import { library } from '@fortawesome/fontawesome-svg-core'
import {faHome, faUsers, faArrowDown, faArrowUp, faPhone, faPlus } from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";
import App from './App.vue'
import store from './store'
import i18n from "./i18n";
import * as moment from "moment";

library.add(faUsers);
library.add(faHome)
library.add(faArrowDown);
library.add(faArrowUp);
library.add(faPhone);
library.add(faPlus);

moment.locale('fr')

const app = createApp(App);

app.component('font-awesome-icon', FontAwesomeIcon)

app.use(i18n);
app.use(store);
app.use(router);

app.mount('#app')
