import {storeAuthToken} from "@/api/login.api";

const saveTokenOnLoginPlugin = (store) => {
    store.subscribe((mutation, state) => {
        if (mutation.type === 'setToken') {
            storeAuthToken(state.auth.token);
        }
    })
};

export default saveTokenOnLoginPlugin;
