<template>
  <div class="container p-2 input-group">
    <input class="form-control form-control-sm"
           type="text"
           :placeholder="$t('Search')"
           @keyup="clearFilterIfEscape"
           v-model="filter" />
    <button class="btn btn-sm btn-success px-3" @click="editOrCreateCustomer()">
      <font-awesome-icon icon="plus" size="sm" />
      &nbsp;
      {{ $t('New') }}
    </button>
  </div>
  <div class="table-responsive">
    <table class="table text-nowrap">
      <thead>
        <tr>
          <th></th>
          <th @click="toggleSort('lastName')">
            {{ $t('Last name')}}
            <span v-if="this.sortBy === 'lastName'">
              &nbsp;
              <font-awesome-icon v-if="this.inverseSort" icon="arrow-up" />
              <font-awesome-icon v-else icon="arrow-down" />
            </span>
          </th>
          <th @click="toggleSort('firstName')">
            {{ $t('First name')}}
            <span v-if="this.sortBy === 'firstName'">
              &nbsp;
              <font-awesome-icon v-if="this.inverseSort" icon="arrow-up" />
              <font-awesome-icon v-else icon="arrow-down" />
            </span>
          </th>
          <th>{{ $t('Age') }}</th>

          <th @click="toggleSort('customerSince')">
            {{ $t('Customer since')}}
            <span v-if="this.sortBy === 'customerSince'">
              &nbsp;
              <font-awesome-icon v-if="this.inverseSort" icon="arrow-up" />
              <font-awesome-icon v-else icon="arrow-down" />
            </span>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="customer in filteredCustomers" :key="customer.id" @click="editOrCreateCustomer(customer)">
          <td>
            <button class="btn btn-sm btn-danger" @click.stop="callTelephone(customer.telephone)">
              <font-awesome-icon icon="phone" />
            </button>
          </td>
          <td class="align-middle">{{ customer.lastName }}</td>
          <td class="align-middle">{{ customer.firstName }}</td>
          <td class="align-middle">{{ customer.age() }}</td>
          <td class="align-middle">{{ customer.customerSince ? customer.customerSince.format('L') : '' }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  data() {
    return {
      filter: '',
      sortBy: 'lastName',
      inverseSort: false,
    }
  },
  computed: {
    ...mapState({
      customers: state => state.customers.customers,
    }),
    filteredCustomers: function () {
      const filteredCustomers = [...this.customers].filter(customer => {
        if (!this.filter || customer.match(this.filter)) {
          return customer;
        }
      })
      filteredCustomers.sort((a, b) => {
        if (this.sortBy === 'customerSince') {
          if (this.inverseSort) {
            return b.customerSince - a.customerSince;
          }
          return a.customerSince - b.customerSince;
        } else {
          const valueA = this.sortBy ? a[this.sortBy] : a.fullname();
          const valueB = this.sortBy ? b[this.sortBy] : b.fullname();
          if (this.inverseSort) {
            return valueB.localeCompare(valueB);
          }
          return valueA.localeCompare(valueB);
        }
      });
      return filteredCustomers;
    }
  },
  methods: {
    toggleSort: function (sortBy) {
      if (this.sortBy === sortBy) {
        if (this.inverseSort) {
          this.inverseSort = false;
        } else {
          this.inverseSort = true;
        }
      } else {
        this.sortBy = sortBy;
        this.inverseSort = false;
      }
    },
    editOrCreateCustomer: function (customer) {
      if (customer && customer.id) {
        this.$router.push(`/customer/${customer.id}`);
      } else {
        this.$router.push('/customer/new');
      }
    },
    callTelephone: function (telephone) {
      window.open(`tel:${telephone}`);
    },
    clearFilterIfEscape: function (event) {
      if (event && event.key === 'Escape') {
        this.filter = '';
      }
    }
  },
};
</script>
